<template>
	<div class="card-container" :style="{ '--dynamic-color': colorLine }">
		<div class="card-header">
			<div class="header-name-ship shorten-text-header" :title="movement.vesselname">{{ movement.vesselname }}</div>
			<div class="header-information-ship">
				{{ movement.vesselimo }} · {{ movement.callsign }} · {{ movement.length }}m ·
				<v-icon class="mr-2 rotated-icon" small>fas fa-water-rise</v-icon> {{ movement.draught }}m
			</div>
		</div>
		<div class="movement-information">
			<div class="data-movement-information">
				<div class="header-data-movement">
					<span class="data-text shorten-text-header" :title="movement.shiptype">{{ movement.shiptype || 'NO INFORMADO' }}</span
					><span class="data-text">{{ movement.portcallnumber }}</span
					><span class="data-text">{{ movement.movementtype }}</span>
				</div>
				<div class="horizontal-container">
					<div class="icon-container">
						<v-icon class="mr-2 icon-with-bg" :style="{ backgroundColor: backgroundColor }" small>fal fa-dharmachakra</v-icon>
					</div>
					<div class="dates-container">
						<div class="shorten-text-berths">
							<v-icon class="mr-2" small :style="{ color: colorForDates(movement) }">far fa-crosshairs</v-icon>
							<span class="dates-text" :title="movement.locationfromname" :style="{ color: colorForDates(movement) }">
								{{ parseDateArrival(movement) }} {{ movement.locationfromname }}</span
							>
						</div>
						<div class="shorten-text-berths">
							<v-icon class="mr-2" small>fas fa-flag</v-icon>
							<span class="dates-text" :title="movement.locationtoname">
								{{ parseDateDeparture(movement) }} {{ movement.locationtoname }}</span
							>
						</div>
					</div>
					<div
						class="waypoint-container shorten-text-waypoint"
						:title="this.movement.lastwaypointname"
						v-if="this.movement.lastwaypointdate != null && this.movement.lastwaypointname != null"
					>
						<v-icon class="mr-2" small>fas fa-map-marker-alt</v-icon>
						<span class="dates-text">{{ parseDateWaypoint(movement) }} {{ this.movement.lastwaypointname }}</span>
					</div>
					<div class="waypoint-container" v-else>
						<v-icon class="mr-2" small>fas fa-map-marker-alt</v-icon>
						<span class="dates-text">Sin datos</span>
					</div>
				</div>
			</div>
			<div class="status-movement-information">
				<!-- <div class="status-item" :style="{ backgroundColor: backgroundColorStatusStop }">
					{{ 'En fondeo' || 'Sin datos' }}
				</div> -->
				<div class="status-item" :style="{ backgroundColor: backgroundColorStatusTns }">{{ movement.movementstatus }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'movementsmappanelcard',

	props: {
		movement: {
			type: Object,
			required: true
		},
		colorLine: {
			type: String,
			required: true
		}
	},

	components: {},

	data() {
		return {};
	},
	created() {},
	computed: {
		backgroundColor() {
			if (this.movement.pilotage) {
				return '#014efa';
			} else {
				return 'grey';
			}
		},
		backgroundColorStatusStop() {
			return '#69B8FF';
		},
		backgroundColorStatusTns() {
			return this.movement.statuscolor;
		}
	},
	methods: {
		parseDateArrival(movement) {
			if (movement.movementstatuscode == 'AC' || movement.movementstatuscode == 'AU') {
				const date = new Date(movement.starttime);

				const day = String(date.getDate()).padStart(2, '0');
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');

				const formattedDate = `${day}/${month} ${hours}:${minutes}`;
				return formattedDate;
			} else if (movement.movementstatuscode == 'IN') {
				const date = new Date(movement.starttime);

				const day = String(date.getDate()).padStart(2, '0');
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');

				const formattedDate = `${day}/${month} ${hours}:${minutes}`;
				return formattedDate;
			}
		},
		parseDateDeparture(movement) {
			if (movement.movementstatuscode == 'AC' || movement.movementstatuscode == 'AU') {
				const date = new Date(movement.endtime);

				const day = String(date.getDate()).padStart(2, '0');
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');

				const formattedDate = `${day}/${month} ${hours}:${minutes}`;
				return formattedDate;
			} else if (movement.movementstatuscode == 'IN') {
				const date = new Date(movement.endtime);

				const day = String(date.getDate()).padStart(2, '0');
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');

				const formattedDate = `${day}/${month} ${hours}:${minutes}`;
				return formattedDate;
			}
		},
		parseDateWaypoint(movement) {
			const date = new Date(movement.lastwaypointdate);

			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');

			const formattedDate = `${hours}:${minutes}`;
			return formattedDate;
		},
		colorForDates(movement) {
			if (movement.movementstatuscode == 'AC' || movement.movementstatuscode == 'AU') {
				const etaDate = new Date(movement.starttime);
				const currentDate = new Date();
				if (etaDate > currentDate) {
					return 'grey';
				} else {
					return 'red';
				}
			}
			if (movement.movementstatuscode == 'IN') {
				const etaDate = new Date(movement.starttime);
				const currentDate = new Date();
				if (etaDate > currentDate) {
					return 'red';
				} else {
					return 'black';
				}
			}
		}
	},
	watch: {}
};
</script>

<style lang="postcss" scoped>
.card-container {
	width: 98%;
	max-width: 600px;
	padding: 10px;
	padding-left: 15px;
	border: 2px solid #dcdcdc;
	border-radius: 10px;
	position: relative;
	margin: 0 auto;
	background-color: white;
}

.card-container::before {
	content: '';
	position: absolute;
	left: 5px;
	top: 4px;
	bottom: 4px;
	width: 6px;
	background-color: var(--dynamic-color, grey);
}

.card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-left: 10px;
}

.movement-information {
	display: flex;
	width: 100%;
}

.data-movement-information {
	width: 75%;
	padding: 10px 10px 0 10px;
}

.status-movement-information {
	width: 25%;
	padding: 10px 10px 0 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.header-data-movement {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.data-text {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: center;
}
.icon-with-bg {
	color: white;
	padding: 3px;
}
.dates-text {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: center;
}
.status-item {
	text-align: center;
	color: white;
	border-radius: 2px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 0px;
	padding: 4px;
}
.header-name-ship {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0px;
}
.rotated-icon {
	transform: rotate(180deg);
}
.header-information-ship {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height: 10px;
	letter-spacing: 0px;
	text-align: center;
}
.shorten-text-header {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 40%;
}

.shorten-text-berths {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 95%;
}
.shorten-text-waypoint {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 95%;
}
.horizontal-container {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
}

.icon-container,
.dates-container,
.waypoint-container {
	box-sizing: border-box;
}

.icon-container {
	width: 10%;
}
.dates-container {
	width: 54%;
}
.waypoint-container {
	width: 36%;
}
</style>
