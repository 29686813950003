<template>
	<div class="d-flex flex-column movementsmappanel fill-height max-height-100">
		<div class="d-flex flex-column py-6 px-3 mb-3" ref="header">
			<div class="movements-title">{{ $t('operations.movements.title') }}</div>
		</div>
		<div class="filter-title-container">
			<span class="filters-text" @click="showFilters"
				>{{ $t('operations.movements.filters') }}
				<v-icon v-if="this.showFiltersContainer" class="mr-2" small color="var(--primary)">far fa-chevron-up</v-icon
				><v-icon v-else class="mr-2" small color="var(--primary)">far fa-chevron-down</v-icon></span
			>
		</div>
		<div class="filter-container" v-if="this.showFiltersContainer">
			<v-row no-gutters
				><v-col cols="6">
					<pui-select
						:id="`movementstatuscode-${modelName}`"
						:attach="`movementstatuscode-${modelName}`"
						:label="$t('form.movement.status')"
						toplabel
						clearable
						v-model="model"
						modelName="movementstatus"
						:itemsToSelect="[{ name: model.movementstatus }]"
						:modelFormMapping="{ name: 'movementstatus' }"
						itemValue="name"
						itemText="name"
						:fixedFilter="filterStatusMovements"
						reactive
					></pui-select> </v-col
				><v-col cols="6">
					<pui-select
						:id="`type-${modelName}`"
						:attach="`type-${modelName}`"
						:label="$t('form.movement.type')"
						toplabel
						clearable
						v-model="model"
						modelName="movementtype"
						:itemsToSelect="[{ name: model.movementtype }]"
						:modelFormMapping="{ name: 'movementtype' }"
						itemValue="name"
						itemText="name"
						reactive
					></pui-select>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="6">
					<pui-select
						id="type"
						attach="type"
						:label="$t('form.ship.type')"
						toplabel
						clearable
						v-model="model"
						modelName="shiptypepa"
						:itemsToSelect="[{ description: model.shiptype }]"
						:modelFormMapping="{ description: 'shiptype' }"
						itemValue="description"
						itemText="description"
						reactive
					></pui-select>
				</v-col>
				<v-col cols="6" class="pilotage-container">
					<pui-checkbox
						:id="`pilotage-locationsequenceheader`"
						:label="$t('form.locationsequenceheader.pilotage')"
						v-model="model.pilotage"
					></pui-checkbox>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="6">
					<pui-date-field
						:id="`starttime-${modelName}`"
						v-model="model.starttime"
						:label="$t('grid.resources.since')"
						toplabel
						clearable
					></pui-date-field
				></v-col>
				<v-col cols="6">
					<pui-date-field
						:id="`endtime-${modelName}`"
						v-model="model.endtime"
						clearable
						:label="$t('grid.resources.to')"
						toplabel
					></pui-date-field
				></v-col>
			</v-row>
		</div>
		<div class="estado-fechas">
			<span class="text-dates-legend">Estado fechas: </span>
			<div class="estado">
				<span class="circle iniciado"></span>
				<span class="label">Iniciado</span>
			</div>
			<div class="estado">
				<span class="circle previsto"></span>
				<span class="label">Previsto</span>
			</div>
			<div class="estado">
				<span class="circle retraso"></span>
				<span class="label">Retraso</span>
			</div>
		</div>
		<div class="cards-container" v-for="(movement, index) in this.movements_filtered" :key="index">
			<movements-map-panel-card :movement="movement" :colorLine="getColorLine(movement.movementstatuscode, movement.shiptypepa)" />
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

/** Mixins */
import LayersActiveMixin from '@/mixins/LayersActiveMixin';
import MovementsMapPanelCard from './cards/MovementsMapPanelCard.vue';
import { STATUS_VIS, TYPE_VIS, MAP_AIS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

export default {
	name: 'movementsmappanel',

	mixins: [LayersActiveMixin],

	props: {
		aisStops: {
			type: Array,
			required: true
		},
		currentmap: {
			type: String,
			required: true
		}
	},

	components: { MovementsMapPanelCard },

	data() {
		return {
			model: {
				movementstatus: null,
				movementtype: null,
				shiptype: null,
				pilotage: true,
				starttime: null,
				endtime: null
			},
			showFiltersContainer: true,
			modelName: 'movementmappanel',
			movements: [],
			movements_filtered: [],
			movements_waypoints: []
		};
	},
	created() {
		if (this.mapAisIsLoaded) {
			const mapFitPortExtent = this.$store.getters.getMapAisFitPortExtent;
			if (mapFitPortExtent) {
				ol2map.zoomToExtent(mapFitPortExtent);
			}
		}
		this.getMovements();
	},
	computed: {
		mapAisIsLoaded() {
			return this.$store.getters.getMapAisIsLoaded;
		},
		filterStatusMovements() {
			return {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'movement_status_code', op: 'eq', data: 'AU' },
					{ field: 'movement_status_code', op: 'eq', data: 'AC' },
					{ field: 'movement_status_code', op: 'eq', data: 'IN' }
				]
			};
		}
	},
	methods: {
		showFilters() {
			this.showFiltersContainer = !this.showFiltersContainer;
		},
		async getMovements() {
			const workingPortAuthorityId = window.localStorage.getItem('workingPortAuthorityId');
			const workingPortsId = window.localStorage.getItem('workingPortsId');

			let opts = {
				model: 'vmovementcards',
				rows: 999,
				order: [{ column: 'id', direction: 'desc' }],
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'port_authority_id', op: 'eq', data: workingPortAuthorityId }]
				}
			};

			if (workingPortsId) {
				opts.filter.rules.push({ field: 'port_id', op: 'in', data: workingPortsId });
			}

			const response = await this.$puiRequests.postRequest('/puisearch', opts);

			this.movements = response.data.data;
			this.model.starttime = new Date();
			let endDate = new Date();
			endDate.setDate(endDate.getDate() + 2);

			this.model.endtime = endDate;
		},
		filterWaypoints() {
			this.movements.forEach((movement) => {
				const existingMovementIndex = this.movements_waypoints.findIndex((m) => m.id === movement.id);
				if (existingMovementIndex === -1) {
					this.movements_waypoints.push(movement);
				} else {
					const existingMovement = this.movements_waypoints[existingMovementIndex];
					if (new Date(movement.lastwaypointdate) > new Date(existingMovement.lastwaypointdate)) {
						this.movements_waypoints.splice(existingMovementIndex, 1, movement);
					}
				}
			});
			this.movements_filtered = this.movements_waypoints;
		},
		filterMovementsList() {
			this.movements_filtered = this.movements_waypoints.filter((movement) => {
				let isValid = true;

				if (this.model.movementstatus !== null) {
					isValid = isValid && movement.movementstatus === this.model.movementstatus;
				}

				if (this.model.movementtype !== null) {
					isValid = isValid && movement.movementtype === this.model.movementtype;
				}

				if (this.model.shiptype !== null) {
					isValid = isValid && movement.shiptype === this.model.shiptype;
				}

				if (this.model.pilotage !== null) {
					isValid = isValid && movement.pilotage === this.model.pilotage;
				}

				if (this.model.starttime !== null) {
					if (movement.movementstatuscode == 'AC' || movement.movementstatuscode == 'AU') {
						isValid = isValid && new Date(movement.starttime) >= new Date(this.model.starttime);
					} else if (movement.movementstatuscode == 'IN') {
						isValid = isValid && new Date(movement.starttime) >= new Date(this.model.starttime);
					}
				}

				if (this.model.endtime !== null) {
					if (movement.movementstatuscode == 'AC' || movement.movementstatuscode == 'AU') {
						isValid = isValid && new Date(movement.endtime) <= new Date(this.model.endtime);
					} else if (movement.movementstatuscode == 'IN') {
						isValid = isValid && new Date(movement.endtime) <= new Date(this.model.endtime);
					}
				}

				return isValid;
			});
		},
		getColorLine(movementstatuscode, shiptype) {
			this.currentmap == MAP_AIS_ID ? this.$store.getters.getMapAisStyleVisualization : this.$store.getters.getMapPeriodsStyleVisualization;
			let objecte = null;
			if (this.isMapStatusStyleVisualization()) {
				let colors = this.$store.getters.stopsStatusValues;
				objecte = colors.find((obj) => obj.stopstatuscode === movementstatuscode);
			} else if (this.isMapTypeStyleVisualization()) {
				let colors = this.$store.getters.stopsTypeValues;
				return colors[shiptype] ? colors[shiptype].color : 'grey';
			}

			return objecte ? objecte.colorhex : null;
		},
		isMapStatusStyleVisualization() {
			if (this.isCurrentMapAis) {
				return this.$store.getters.getMapAisStyleVisualization === STATUS_VIS;
			} else {
				return this.$store.getters.getMapPeriodsStyleVisualization === STATUS_VIS;
			}
		},
		isMapTypeStyleVisualization() {
			if (this.isCurrentMapAis) {
				return this.$store.getters.getMapAisStyleVisualization === TYPE_VIS;
			} else {
				return this.$store.getters.getMapPeriodsStyleVisualization === TYPE_VIS;
			}
		},
		isCurrentMapAis() {
			return this.currentmap == MAP_AIS_ID;
		}
	},
	watch: {
		movements: {
			handler(newVal, oldVal) {
				this.filterWaypoints();
			},
			deep: true
		},
		model: {
			handler(newVal, oldVal) {
				this.filterMovementsList();
			},
			deep: true
		}
	}
};
</script>

<style lang="postcss" scoped>
.movementsmappanel {
	background-color: #f7f8fc;
	& .movements-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
		margin-bottom: 2px;
	}
}

.filter-title-container {
	padding: 10px 20px;
	text-align: right;
	margin-bottom: 20px;
}

.filters-text {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0px;
	text-align: center;
	color: #014efa;
	margin-right: 7px;
	cursor: pointer;
	user-select: none;
}

.cards-container {
	display: flex;
	justify-content: center;
	margin-bottom: 6px;
}
.pilotage-container {
	display: flex;
	align-items: center;
}
.filter-container {
	padding: 0 15px;
}
.estado-fechas {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 10px;
}

.estado {
	display: flex;
	align-items: center;
	margin: 0 10px;
}

.circle {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-right: 5px;
}

.iniciado {
	background-color: black;
}

.previsto {
	background-color: gray;
}

.retraso {
	background-color: red;
}

.label {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	font-size: 11px;
	line-height: 16px;
	letter-spacing: 0px;
	vertical-align: middle;
}
.text-dates-legend {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0px;
	vertical-align: middle;
}
</style>
